@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.snapshottable {
  border: 0;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
}
.grad {
  background: rgb(255, 0, 0);
  background: linear-gradient(
    180deg,
    var(--temp0) 0%,
    var(--temp1) var(--gradientmiddle),
    var(--temp2) 100%
  );
  flex-grow: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 100px;
}
.textcontainer {
  text-align: center;
  margin: 1rem;
}
.textbox {
  background-color: gainsboro;
  color: black;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 1rem;
}

.page {
  min-height: calc(100vh - 4.25rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.box {
  min-width: 375px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem;
  /* margin: 1rem; */
}
.box > * {
  flex: 1 1 100%;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.weather {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.extra {
  min-width: 375px;
}

